<script setup lang="ts">
import { StagePlayScene } from "vue-stage-play";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { SpeedInsights } from "@vercel/speed-insights/nuxt";
import Smartlook from "smartlook-client";
import type { InitParams } from "smartlook-client";

const config = useRuntimeConfig();
const store = useGeneralStore();
const navigation =
  store.plan === "prod_R0NlZBdGkmDNwZ"
    ? [
      { name: "Module", current: false, icon: ["fas", "fa-circle-play"] },
      { name: "Storage", current: false, icon: ["fas", "fa-cloud-arrow-up"] },
      { name: "Analytics", current: false, icon: ["fas", "fa-chart-simple"] },
    ]
    : [
      { name: "Module", current: false, icon: ["fas", "fa-circle-play"] },
      { name: "Storage", current: false, icon: ["fas", "fa-cloud-arrow-up"] },
      { name: "Analytics", current: false, icon: ["fas", "fa-chart-simple"] },
      { name: "Pricing", current: false, icon: ["fas", "fa-crown"] },
    ];

const route = useRoute();
const { t } = useI18n();

const user = useSupabaseUser();
const sidebarOpen = ref(false);
const videoWidth = ref(0);

onMounted(async () => {
  const params: InitParams = {
    advancedNetwork: {
      allowedUrls: [
        "https://app.vendeo.io/",
        "https://app.vendeo.io/signup",
        "https://app.vendeo.io/confirm",
        "https://app.vendeo.io/forgot-password",
        "https://app.vendeo.io/reset-password",
        /^https:\/\/app\.vendeo\.io\/app\/.*$/,
      ],
    },
  };
  if (config.public.appEnv === "production" && !Smartlook.initialized())
    Smartlook.init("76c6ff7d54a2694a36bb1371e84ea31d8c42cf7c", params);

  // Calculate the width of the video tutorial according to the window width
  videoWidth.value = (3 * window.innerWidth) / 5;
  window.addEventListener("resize", () => {
    videoWidth.value = (3 * window.innerWidth) / 5;
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", () => {
    videoWidth.value = (3 * window.innerWidth) / 5;
  });
});
</script>

<template>
  <div class="bg-primary">
    <SpeedInsights />
    <div class="md:flex flex-row w-full hidden">
      <StagePlayScene
        :act-name="'tour'"
        :scene="1"
        :voice-over-width="videoWidth"
        :camera-follow="false"
        :voice-over-title="
          $t('intro.default.title', {
            name: user?.user_metadata?.full_name?.split(' ')[0] || '',
          })
        "
      >
        <template #voBody>
          <div class="flex flex-col gap-4">
            <VideoTuto />
            <div v-html="$t('intro.default.description')"></div>
          </div>
        </template>
        <template #voFooterButton="scopedProps">
          <button
            v-if="scopedProps"
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-accent hover:scale-105 transition duration-300 ease-in-out"
            @click="scopedProps.nextScene()"
          >
            {{ $t("global.ready") }}
          </button>
        </template>
      </StagePlayScene>
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog
          as="div"
          class="relative z-40 md:hidden"
          @close="sidebarOpen = false"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 flex">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel
                class="relative flex w-full max-w-xs flex-1 flex-col bg-primary pt-5 pb-4"
              >
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      @click="sidebarOpen = false"
                    >
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon
                        class="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>
                <div
                  class="border-b border-gray-700 flex flex-shrink-0 items-center px-4 justify-center"
                >
                  <Logo />
                </div>
                <div class="mt-5 h-0 flex-1">
                  <nav class="space-y-1 px-2">
                    <div v-for="item in navigation" :key="item.name">
                      <StagePlayScene
                        :act-name="'tour'"
                        :scene="
                          parseInt($t(`intro.${item.name.toLowerCase()}.scene`))
                        "
                        :camera-follow="false"
                        :voice-over-title="
                          $t(`intro.${item.name.toLowerCase()}.title`)
                        "
                        :voice-over-next-button-text="$t('global.next')"
                        :voice-over-prev-button-text="$t('global.prev')"
                        :voice-over-done-button-text="$t('global.done')"
                        :voice-over-auto-placement="false"
                        :voice-over-content="
                          $t(`intro.${item.name.toLowerCase()}.description`)
                        "
                      >
                        <NuxtLink
                          :to="{ name: item.name }"
                          :class="[
                            item.name === route.name
                              ? 'bg-accent-faded text-accent'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-primary font-medium flex items-center gap-2',
                          ]"
                          :aria-current="item.current ? 'page' : undefined"
                        >
                          <font-awesome-icon :icon="item.icon" :size="'md'" />
                          {{ t("navigation." + item.name.toLowerCase()) }}
                        </NuxtLink>
                      </StagePlayScene>
                    </div>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
            <div class="w-14 flex-shrink-0" aria-hidden="true">
              <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div
        class="hidden md:inset-y-0 md:flex md:w-52 md:flex-col min-h-screen bg-accent/15 border border-gray-700/30"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex min-h-0 flex-1 flex-col">
          <div class="flex h-16 flex-shrink-0 items-center justify-center">
            <nuxt-link to="/" class="flex items-center">
              <Logo />
              <span class="text-primary font-bold text-xl ml-2">Vendeo</span>
            </nuxt-link>
          </div>
          <div class="flex flex-1 flex-col">
            <nav class="flex-1 space-y-1 px-2 py-4">
              <div v-for="item in navigation" :key="item.name" class="relative">
                <StagePlayScene
                  :act-name="'tour'"
                  :scene="
                    parseInt($t(`intro.${item.name.toLowerCase()}.scene`))
                  "
                  :voice-over-title="
                    $t(`intro.${item.name.toLowerCase()}.title`)
                  "
                  :voice-over-next-button-text="$t('global.next')"
                  :voice-over-prev-button-text="$t('global.prev')"
                  :voice-over-done-button-text="$t('global.done')"
                  :voice-over-auto-placement="true"
                  :voice-over-content="
                    $t(`intro.${item.name.toLowerCase()}.description`)
                  "
                >
                  <NuxtLink
                    :to="{ name: item.name }"
                    :id="item.name"
                    :class="[
                      item.name === route.name
                        ? 'bg-accent-faded text-accent'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      ' px-3 py-2 rounded-md text-primary font-medium flex items-center gap-2',
                    ]"
                    :aria-current="item.current ? 'page' : undefined"
                  >
                    <font-awesome-icon :icon="item.icon" :size="'md'" />
                    {{ t("navigation." + item.name.toLowerCase()) }}
                    <BetaBadge v-if="item.name === 'Analytics'" />
                  </NuxtLink>
                </StagePlayScene>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full min-h-screen">
        <div class="top-0 z-10 flex h-16 flex-shrink-0 bg-primary shadow">
          <button
            type="button"
            class="border-r px-4 text-gray-500 focus:outline-none border-b border-gray-700 md:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div
            class="flex flex-1 justify-between px-4 border-b border-gray-700"
          >
            <AdminTools />
            <div class="ml-4 flex items-center md:ml-auto">
              <Tools />
              <ProfilTool />
            </div>
          </div>
        </div>

        <main class="flex-1">
          <!-- Alert info to go to tour (removable one time) -->

          <div class="py-6">
            <div class="mx-auto px-4 sm:px-6 md:px-8">
              <slot />
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- Say that the dashboard is available in desktop only-->
    <div class="md:hidden">
      <div class="flex flex-col items-center justify-center h-screen">
        <div class="flex flex-col items-center justify-center">
          <div class="flex items-center justify-center">
            <Logo />
            <span class="text-primary font-bold text-xl ml-2">Vendeo</span>
          </div>
          <div class="flex flex-col items-center justify-center mt-8">
            <h1 class="text-3xl font-bold text-center">
              {{ $t("global.desktop_only") }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
html,
body {
  overflow: auto !important;
}
.swal2-shown {
  overflow: unset !important;
  padding-right: 0px !important;
}
</style>
