import { useGeneralStore } from "~/stores/generalStore";

export const useAnotherAccount = () => {
  const generalStore = useGeneralStore();
  const loading = ref(false);

  const initAnotherAccount = async (email: string) => {
    if (!email) {
      console.error("Email is required");
      return;
    }
    loading.value = true;

    const { data, error } = await useFetch<User>("/api/user", {
      method: "post",
      body: { email: email },
    });

    loading.value = false;

    if (error.value) {
      console.error(error.value);
      return;
    }

    const period_end_tmp = data.value?.body.period_end_tmp ?? null;
    const account_id = data.value?.body.account_id ?? "";
    const plan = (data.value?.body.plan as string) ?? "";

    generalStore.setExpirationDate(period_end_tmp);
    generalStore.setAccount(account_id);
    generalStore.setPlan(plan);
    generalStore.discover = true;

    location.reload();
    // await navigateTo("/app/");
  };

  return { loading, initAnotherAccount };
};
